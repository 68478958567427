import React, {useEffect} from "react";
import {StaticImage} from "gatsby-plugin-image"

import LayoutWidget from "../../../components/widgets/layout";

const AnotherAccountWidget = () => {
    useEffect(() => {
        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.innerHTML = `window.RTR_ACCESS_TOKEN = 'W893da50ba322a648d29130ecdd5197ff';`;
        document.head.appendChild(script2);

        return () => {
            document.head.removeChild(script2);
        }
    }, []);

    return (
        <LayoutWidget>
            <div className="hidden text-center lg:block">
                <StaticImage
                    src="../../../images/tesla.jpg"
                    alt="Tesla red"
                    layout="constrained"
                    width={1440}
                    quality="80"
                />
            </div>

            <div className="flex justify-center mt-6 lg:px-8">
                <rtr-article-booking article-id="XC6OKJ"/>
            </div>
            <div className="flex justify-center mt-6 lg:px-8">
                <rtr-search view="slots"/>
            </div>
        </LayoutWidget>
    )
};

export default AnotherAccountWidget;
